import Ajax from '@utils/ajax';
import { getFormData } from 'BambooHR.util';
import {
	replaceXmlEntities,
} from 'String.util';
import SavesToDisclaimer from 'Esignatures.mod/util/saves-to-disclaimer-call';

/**
 * JS for 'Send Signature Request' modal
 */
export let SendSignatureRequest = (function() {

	///////////////////////////////////////////////////////////////////////////////
	// begin autoInputList hack for immediate results + 'All Employees' behavior //
	///////////////////////////////////////////////////////////////////////////////

	var $input = null;
	var $results = null;

	/**
	 * Reset the SendSignatureRequest modal to original state
	 */
	function reset() {
		$input = null;
		$results = null;
	}

	/**
	 * Add event listeners
	 */
	function addEventListeners() {
		removeEventListeners();
		$('#allEmployees').on('click', function() {
			var $inputList = $('.input-list');
			if ($(this).is(':checked')) {
				$inputList.addClass('disabled').attr('disabled');
				$inputList.siblings('label').addClass('disabled');
			} else {
				$inputList.removeClass('disabled').removeAttr('disabled');
				$inputList.siblings('label').removeClass('disabled');
			}
		});
		$('.js-toggleEmployeeInputList').on('click', (e) => {
			if (e.target.id === 'someEmployeesRadioOption') {
				$('.js-EmployeeInputList').removeClass('hidden');
			} else if (e.target.id === 'allEmployeesRadioOption') {
				$('.js-EmployeeInputList').addClass('hidden');
			}
		});
	}

	function addDisclaimer(templateId) {
		SavesToDisclaimer.fetchDisclaimer(templateId);
	}

	/**
	 * Remove event listeners
	 */
	function removeEventListeners() {
		$('#allEmployees').off('click');
	}

	/**
	 * Get a cached wrapper of the autocomplete input
	 *
	 * @return jQuery the input
	 */
	function getInput() {
		if ($input && $input.length) {
			return $input;
		}
		$input = $('.free.ac_input');
		return $input;
	}

	/**
	 * Get a cached wrapper of the autocomplete results
	 *
	 * @return jQuery the results
	 */
	function getResults() {
		if ($results && $results.length) {
			return $results;
		}
		$results = $('.ac_results');
		return $results;
	}

	var downArrowEvent = new jQuery.Event('keydown', {which: 40, keyCode: 40});

	/**
	 * Remove an item from the 'tag' area
	 *
	 * @return false prevent default/propagation
	 */
	function removeTag() {
		var parent = $(this).closest('span.item');
		parent.remove();
		var noMoreTags = $('.inputs input').length === 1;
		if (noMoreTags) {
			getInput()
				.setOptions({extraParams: {all: true}})
				.flushCache()
				.removeAttr('style');
		}
		return false;
	}

	/**
	 * Format/insert the 'tag' view for each selected item
	 *
	 * @param event event item selection from autocomplete
	 * @param array data  a data row from server
	 */
	function formatTagItem(event, data) {
		var identifier = xmlEntities(data[2]);
		var type = xmlEntities(data[1]);

		//Create the label differently if no users found
		const foundEmployeeMatch = type !== 'empty';
		var label = foundEmployeeMatch ? replaceXmlEntities(data[0]) : `${ replaceXmlEntities(data[0]) } ${ identifier }`;
		var tagId = foundEmployeeMatch ? type + '-' + identifier : type;
		var name = type + 'Ids';

		if (type === 'all') {
			getInput()
				.css({
					'position': 'fixed',
					'left': -9999
				});
			getResults().css('left', -9999);
		}

		$(`<span class="item" id="${ tagId }">${ label }<input type="hidden" name="${ name }[]" value="${ identifier }" /><ba-icon class="remove" name="fab-x-8x8" encore-name="xmark-solid" encore-size="16" encore-class="remove"></ba-icon>`)
			.insertBefore('.input-list span.input');

		getInput().val('');

		$('#' + tagId + ' .remove').on('click', removeTag);
	}

	/**
	 * Enable the autoInputList
	 *
	 * @param string description placeholder text for input
	 */
	function enableAutoInputList(description) {
		$('.input-list').autoInputList({
			autocompleteUrl: '/quicksearch/request/esignature',
			description: description,
			extraParams: {all: false},
			formatAutoItem: qsWithPhoto,
			formatTagItem: formatTagItem,
			autocompleteOptions: {
				highlight: BambooHR.Utils.highlightTermInMarkup,
				minChars: 1,
				scroll: false,
				max: 4
			}
		}).activate();
	}

	///////////////////////////////////////////////////////////////////////////////
	// end autoInputList hack                                                    //
	///////////////////////////////////////////////////////////////////////////////

	/**
	 * 
	 * Open the stacked modal for selection of employees and initiation of
	 * signature request
	 * @param {int} esignatureTemplateId 
	 * @param {string} title 
	 * @param {bool} isSheet 
	 */
	function openEsignatureModal(esignatureTemplateId, title, isSheet = true) {
		const url = `/ajax/files/send_signature_request.php?esignatureTemplateId=${ esignatureTemplateId }`;
		var that = this;

		const loadingProps = { isLoading: true, isOpen: true };
		if (isSheet) {
			window.BambooHR.Modal.setState({ ...loadingProps, sheetProps: null, type: 'smallSheet' });
		} else {
			window.BambooHR.Modal.setState(loadingProps);
		}
		Ajax.get(url).then((response) => {
			if (response && response.data) {
				const modalOptions = {
					biId: 'files-signature-request',
					dangerousContent: response.data,
					isLoading: false,
					onClose: () => {
						removeEventListeners();
						reset();
						window.BambooHR.Modal.setState({
							isOpen: false,
						});
					},
					primaryAction: () => { that.sendSignatureRequest(esignatureTemplateId); },
					primaryActionText: $.__('Send Request'),
					title,
				};

				window.BambooHR.Modal.setState({
					...modalOptions
				}, true);

				reset();
				enableAutoInputList($.__('Enter names or groups'));
				addEventListeners();
				addDisclaimer(esignatureTemplateId);
			}
		});
	}

	async function sendSignatureRequest(esignatureTemplateId) {
		window.BambooHR.Modal.setState({
			isProcessing: true
		}, true);

		try {
			const {allIds, employeeIds, groupIds, locationIds, message} = getFormData('.js-send-signature-request-form');
			const postData = {
				employeeIds: allIds === 'on' || typeof(employeeIds) === 'undefined' ? null : employeeIds,
				esignatureTemplateId,
				groupIds: typeof(groupIds) === 'undefined' ? null : groupIds,
				locationIds: typeof(locationIds) === 'undefined' ? null : locationIds,
				mode: allIds === 'on' ? 'all' : 'some',
				note: message || '',
			};

			if (postData.mode === 'some' && postData.employeeIds === null && postData.groupIds === null && postData.locationIds === null) {
				throw new Error('MISSING_IDS');
			}

			const response = await Ajax.post(`/esignature_instances`, postData);
			
			const multipleRequestsSent = postData.mode === 'all' 
				|| postData.employeeIds?.length > 1 
				|| postData.groupIds?.length >= 1
				|| postData.locationIds?.length >= 1;

			let infoMessage = multipleRequestsSent ? $.__('The signature requests have been sent.') : $.__('The signature request has been sent.');
			const expectedDelay = parseInt(response.data?.meta?.expectedDelay || 0);
			const instanceCount = response.data?.meta?.createdInstances || 0;
			// If the expected delay is greater than 2 minutes, we should let the user know that it may take a few
			// minutes.
			if (expectedDelay > 120) {
				infoMessage += ` ${$.__('Please allow a few minutes to send %s signature requests.', instanceCount.toString())}`;
			}
			window.setMessage(infoMessage, 'success');
			document.dispatchEvent(new Event('Files:sendSignatureRequest:requestSent'));
			window.BambooHR.Modal.setState({
				isOpen: false,
			}, true);
		} catch (error) {
			if (error.message === 'MISSING_EMPLOYEE_IDS') {
				$('.js-highlightOnError').addClass('RecipientsSectionLabel--error');
				$('.js-missingDataError').removeClass('hidden');
				window.setMessage($.__('There are no users in this group that are able to sign this document.'), 'error');
				return;
			}

			window.errorFallBack();
		} finally {
			window.BambooHR.Modal.setState({
				isProcessing: false,
				sheetProps: {
					isProcessing: false
				}
			}, true);
		}
	}

	return {
		enableAutoInputList,
		openEsignatureModal,
		sendSignatureRequest,
	};

})();
