import { createStyles, makeStyles } from '@bamboohr/fabric';

export default makeStyles(({ palette, typography }) => {
	return createStyles({
		row: {
			display: 'flex',
			alignItems: 'center',
		},
		collapse: {
			borderBottom: `1px solid ${palette.gray[300]}`,
		},
		collapseHeader: {
			alignItems: 'center',
			justifyContent: 'flex-start',
			paddingLeft: '25px',
			columnGap: '8px',
			paddingTop: '9px',
			paddingBottom: '9px',
			color: palette.gray[900],
			'&:hover, &:focus-visible': {
				color: palette.primary.main,
				backgroundColor: palette.gray[100],
				cursor: 'pointer',
			},
		},
		collapseTitle: {
			paddingLeft: '8px',
		},
		selectedIcon: {
			'& svg': {
				fill: `${palette.primary.main} !important`,
			},
		},
		subCollapse: {
			paddingLeft: '15px',
			'& > div': {
				paddingTop: '3px',
				paddingBottom: '3px',
				'& > span': {
					paddingBottom: '1px',
				},
			},
		},
		preventRotate: {
			transform: 'rotate(0deg) !important',
		},
		rotateChevron: {
			'& ~ svg': {
				transform: 'rotate(90deg)',
			},
		},
		collapseIcon: {
			paddingTop: '5px',
			'& svg': {
				fill: palette.gray[900],
			},
		},
		selectedText: {
			color: palette.primary.main,
			fontWeight: typography.fontWeightSemibold,
		},
		headerDisabled: {
			'& svg': {
				fill: `${palette.gray[500]} !important`,
			},
			'&:hover': {
				'& svg': {
					fill: palette.gray[500],
				},
				cursor: 'default',
				backgroundColor: 'initial',
				color: 'initial',
			},
		},
		headerTextDisabled: {
			color: palette.gray[500],
		},
		addedPadding: {
			paddingLeft: '14px',
		},
		documentTab: {
			justifyContent: 'space-between',
			paddingLeft: 0,
			'& svg': {
				marginRight: '10px',
				marginLeft: '14px',
				fill: palette.gray[900],
			},
			'&:hover': {
				'& svg': {
					fill: palette.primary.main,
				},
			},
		},
		documentTabSelected: {
			color: palette.primary.main,
			'& svg': {
				fill: palette.primary.main,
			},
		},
		documentTabDisabled: {
			cursor: 'default',
			pointerEvents: 'none',
			'& span': {
				color: palette.gray[500],
				'& svg': {
					fill: palette.gray[500],
				},
			},
		},
	});
});
