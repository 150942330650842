import { editFolder, updateFileSectionCounts } from './util';

import { setupShareInputs } from './util/helper';

import BadgeComponent from 'badge.react';

import { confirmUpload } from 'login.mod/ticket-login';
import {
	updateFileUploadActions,
	centerFileUploadActions,
	uploadModalContent,
} from 'FileManager.mod';

export function addFile(folderId, fileRow, employeeFile) {
	let filesIds = [];
	fileRow.each(function (index, item) {
		filesIds.push($(item).data('file_id'));
	});

	let url = '/ajax/files/edit_folder.php?id=' + folderId;
	let fileData = { action: 'addFile', fileIds: filesIds };

	if (employeeFile) {
		// if this is an employee file - mark the new folder as selected so it will be shown
		$('li.sectionLink[data-section_id=' + folderId + ']').addClass('selected');

		url =
			'/employees/ajax/folder/moveFiles/' + window.currentlyEditingEmployeeId;
		fileData = { id: folderId, fileIds: filesIds };
	}

	$.post(
		url,
		fileData,
		function (data) {
			if (data.success) {
				// reload this section via ajax
				let selectedFolder = $('li.sectionLink.selected');
				if (selectedFolder.length) {
					selectedFolder.each(function () {
						const sectionId = $(this).find('.js-FileFolder').data('section_id');

						$('#files-table-wrapper .js-FilesTable').children().remove();
						loadSectionFiles(
							sectionId,
							$('select[name=sort-files]').val(),
							null,
							true,
						);
					});
				} else {
					loadSectionFiles(
						'all',
						$('select[name=sort-files]', null, true).val(),
					);
					$('#file-sections .sectionLink.all').addClass('selected');
				}
				// update folder counts in the UI
				$('li.sectionLink').each(function () {
					let count = 0;

					const sectionId = $(this).find('.js-FileFolder').data('section_id');
					if (sectionId && data.sectionCounts[sectionId]) {
						count = data.sectionCounts[sectionId];
					}

					$(this)
						.find('span.count')
						.html('(' + count + ')');
				});
			} else {
				window.setMessage(data.error, 'error');
			}
		},
		'json',
	);
}

export function upload() {
	// don't allow uploads if there are no folders available
	if (!$('li.sectionLink:not(.all)').length > 0) {
		return editFolder(null, 'first');
	}

	//update the folder drop down in the modal html to reflect the currently open folder
	const footerHtml = $('#uploadFilesFooterDialog').tmpl();
	const selectedFolderId = $('.sectionLink.selected .js-FileFolder').data(
		'section_id',
	);

	let data = $('li.js-canUpload');

	const modalIdSelector = '#modal-dialog';
	const fabricModalSelector = '#fabricModalContent';

	let $select = footerHtml.find('ba-select[name="fileSection"]');
	buildSelectOptions($select, data, selectedFolderId);
	showUploadsModal(footerHtml.html(), () => {
		let selectedSectionId = $('.shareForm select[name=fileSection]').val();
		let $selectedFolder = $('.employeeFolderSelection');
		if ($selectedFolder.length > 0) {
			selectedSectionId = $selectedFolder.data('sectionId');
		}
		if (!selectedSectionId) {
			setMessage($.__('Please select a folder.'), 'error');
			return false;
		}
		window.BambooHR.Modal.setState({ isProcessing: true }, true);
		data = $('#shareInput :input, #shareInput ba-select').serializeArray();
		$.merge(
			data,
			$(fabricModalSelector + ' :input[name^=attachId]')
				.add(fabricModalSelector + ' ba-select[name^=attachId]')
				.serializeArray(),
		);
		data.push(
			{ name: 'action', value: 'upload' },
			{ name: 'fileSection', value: selectedSectionId },
			{ name: 'share', value: $('#allEmployees').is(':checked') },
		);
		$.ajax({
			url: '/ajax/' + appPrefix + 'files/edit_file.php',
			type: 'post',
			data: data,
			success: function (data) {
				if (data.success) {
					updateFileSectionCounts();
					offset = 0;
					doneLoading = false;
					loadSectionFiles(
						selectedSectionId,
						$('ba-select[name=sort-files]').val(),
					);
					// Update to new section if it changed
					$('.js-fileFolderWrapper, .js-FileFolder')
						.removeClass('selected fab-SideNavigation__link--active')
						.find('ba-icon[encore-name$="solid"]')
						.attr('encore-name', function (i, previousValue) {
							return previousValue.replace('solid', 'regular');
						});
					$('[data-section_id="' + selectedSectionId + '"]')
						.addClass('fab-SideNavigation__link--active')
						.parent('.js-fileFolderWrapper')
						.addClass('selected')
						.find('ba-icon[encore-name$="regular"]')
						.attr('encore-name', function (i, previousValue) {
							return previousValue.replace('regular', 'solid');
						});
					setMessage(data.success_message, 'success');
					$('.uploadDesc').show();
					window.BambooHR.Modal.setState({ isOpen: false });
				} else {
					setMessage(data.error, 'error');
					window.BambooHR.Modal.setState({ isProcessing: false }, true);
				}
			},
			dataType: 'json',
		});
	});
	$('.uploadDesc').show();
}

export function uploadEmployeeFile(employeeId, isViewingSelfAsEss = false) {
	// don't allow uploads if there are no folders available or if there is no employee id passed in
	if (!employeeId) {
		return false;
	}
	if (!isViewingSelfAsEss && !$('li.sectionLink:not(.all)').length > 0) {
		return editFolder(null, 'first', true, employeeId);
	}
	//update the folder drop down in the modal html to reflect the currently open folder
	const footerHtml = $('#uploadFilesFooterDialog').tmpl();
	const selectedFolderId = $('.sectionLink.selected').data('section_id');
	let data = $('li.sectionLink.droppable:not(.all)');

	const modalIdSelector = '#modal-dialog';
	const fabricModalSelector = '#fabricModalContent';

	const $select = footerHtml.find('ba-select[name="employeeFileSection"]');
	buildSelectOptions($select, data, selectedFolderId, true);
	showUploadsModal(footerHtml.html(), () => {
		let selectedSectionId = $(
			'.shareForm select[name=employeeFileSection]',
		).val();
		let $selectedFolder = $('.employeeFolderSelection');
		if ($selectedFolder.length > 0) {
			selectedSectionId = $selectedFolder.data('sectionId');
		}
		if (!selectedSectionId) {
			setMessage($.__('Please select a folder.'), 'error');
			return false;
		}
		window.BambooHR.Modal.setState({ isProcessing: true }, true);
		data = $(fabricModalSelector + ' :input[name^=attachId]')
			.add(fabricModalSelector + ' ba-select[name^=attachId]')
			.serializeArray();
		data.push(
			{ name: 'action', value: 'upload' },
			{ name: 'employeeId', value: employeeId },
			{ name: 'employeeFileSection', value: selectedSectionId },
			{ name: 'share', value: $('#shareWithEmployee').is(':checked') },
		);
		if (isViewingSelfAsEss) {
			data.push({
				name: 'isEmployeeDocumentUpload',
				value: isViewingSelfAsEss,
			});
		}
		$.ajax({
			url: '/ajax/' + appPrefix + 'employees/files/edit_file.php',
			type: 'post',
			data: data,
			success: function (data) {
				window.BambooHR.Modal.setState({ isProcessing: false }, true);
				if (data.success) {
					updateFileSectionCounts(employeeId);
					$(
						'li.sectionLink[data-section_id=' + selectedSectionId + ']',
					).addClass('selected');
					loadSectionFiles(
						selectedSectionId,
						$('select[name=sort-files]').val(),
					);
					setMessage(data.success_message, 'success');
					$('.uploadDesc').show();
					window.BambooHR.Modal.setState({ isOpen: false });
				} else {
					setMessage(data.error, 'error');
				}
			},
			dataType: 'json',
		});
	});
	$('.uploadDesc').show();

	// clear out old hidden inputs
	$(modalIdSelector + ' input[name^=attachId]').each(function () {
		$(this).remove();
	});

	$('.ui-dialog-buttonset .ui-button').button('disable');
}

export function updateFooterModal(primaryAction) {
	window.BambooHR.Modal.setState(
		{
			primaryAction(...args) {
				confirmUpload({
					isSheet: true,
				}).then(() => primaryAction(...args));
			},
		},
		true,
	);
}

export function updateUploadInputs(action, primaryAction) {
	if (action === 'enable') {
		let $select = $('.js-uploadFolderSelect');

		if ($select.length === 0) {
			updateFooterModal(primaryAction);
		}
		$('.js-disableShare').attr('disabled', null);
		$('.js-dummyInput').addClass('hidden');
		$('label.js-disableShare').removeClass('disabled fab-Label--disabled');
		$('.js-autoInputListContainer').removeClass('hidden');
	} else {
		window.BambooHR.Modal.setState({ primaryAction: false }, true);
		$('.js-disableShare').attr('disabled', true);
		$('label.js-disableShare').addClass('disabled fab-Label--disabled');
		$('.js-autoInputListContainer').addClass('hidden');
		$('.js-dummyInput').removeClass('hidden');
	}
}

function buildSelectOptions($select, data, selectedFolderId, employeeFiles) {
	if ($select.length > 0) {
		// build the options based on the folders in the left nav
		$select.children().remove();
		for (let i = 0; i < data.length; i++) {
			const jqOpt = $(data[i]).find('.js-FileFolder');
			let opt = document.createElement('ba-option');
			opt.textContent = jqOpt.attr('data-foldername');
			opt.setAttribute('value', jqOpt.attr('data-section_id'));
			if (jqOpt.parent().hasClass('selected')) {
				opt.setAttribute('selected', true);
			}
			$select.append(opt);
		}
		if (selectedFolderId === 'all') {
			$select.find('ba-option').each(function () {
				$(this).removeAttr('selected');
			});
		} else {
			$select.find('ba-option').each(function () {
				if (parseInt($(this).attr('value')) === selectedFolderId) {
					$(this).attr('selected', 'selected');
				} else {
					$(this).removeAttr('selected');
				}
			});
		}
	}
}

function showUploadsModal(footerHtml, primaryAction) {
	const fabricModalSelector = '#fabricModalContent';
	const fabricModalFooterSelector = '#fabricModalFooterContent';
	const employeeHeader = window.Employee ? (
		<BadgeComponent
			imageAlt="Image of employee"
			imageSrc={window.Employee.photoURL}
			subTitle={window.Employee.jobTitle}
			title={window.Employee.preferredName + ' ' + window.Employee.lastName}
		/>
	) : null;

	let $select = $('.js-uploadFolderSelect');

	window.BambooHR.Modal.setState({
		biId: 'files-upload',
		contentHasPadding: true,
		content: <div id="js-uploadFilesContent">{uploadModalContent}</div>,
		footer: (
			<div
				className="FileModalShareOptionsWrapper js-shareOptionsWrapper"
				id="fabricModalFooterContent"
			></div>
		),
		header: employeeHeader,
		onClose: () => {
			$select.off('ba:selectChange');
			updateUploadInputs('disable');
		},
		onOpen: () => {
			//TODO: Remove this once IE11 support is dropped. It is only needed for that browser
			$('#js-uploadFilesContent')
				.find('form.shareForm')
				.prop('name', 'fileUploadForm')
				.prop('id', 'fileUploadForm');
			//END IE11 fix
			const $wrapper = $('.js-shareOptionsWrapper');

			$wrapper.html(footerHtml);

			$select = $wrapper.find('.js-uploadFolderSelect');

			$select.on('ba:selectChange', function (event) {
				if (event.originalEvent.detail.value) {
					updateFooterModal(primaryAction);
				}
			});
			$('.FileUploadActions').addClass('centerContent');
			let context = $('.multiUpload');
			let options = {
				linkHTML: `
					<span class="attach-link">
						<button data-bi-id="files-upload-choose-file" class="fab-Button fab-Button--secondary fab-Button--noSpace js-chooseFile" type="button">
							${'<ba-icon encore-class="fab-Button__icon fab-Button__icon--left" encore-name="up-from-line-regular" encore-size="16"></ba-icon>'}
							${$.__('Choose File(s)')}
						</button>
						<button class="files-add-file fab-TextButton fab-link" type="button" style="display: none;">
							${'<ba-icon encore-class="fab-TextButton__icon fab-TextButton__icon--left" encore-name="up-from-line-regular" encore-size="16"></ba-icon>'}
							${$.__('Add a file')}
						</button>
					</span>
				`,
				success: function (data, elm) {
					$('.noFile', context).hide();
					let form = $('.attach-wrapper', context).closest('form');
					form.append(
						'<input type="hidden" name="attachId[]" data-name="' +
							data.name +
							'" data-type="' +
							data.type +
							'" data-size="' +
							data.size +
							'" data-image="' +
							data.image +
							'" value="' +
							data.fid +
							'">',
					);

					let $select = $('.js-uploadFolderSelect');

					updateUploadInputs('enable');
					if ($select.length === 0 || $select.val()) {
						updateFooterModal(primaryAction);
					}
				},
				removeFile: function (elm) {
					let fid = elm.attr('id').replace('file-', '');
					if (elm.hasClass('uploadDone')) {
						$(`input[name^=attachId][value='` + fid + `']`).remove();
					}
					if (updateFileUploadActions(context)) {
						updateUploadInputs('disable');
					}
					return true;
				},
				onRemove: function () {
					if (updateFileUploadActions(context)) {
						updateUploadInputs('disable');
					}
				},
				createFileWrapper: function (name, progress) {
					$('.noFile', context).hide();
					if ($('.files > *', context).length === 0) {
						$('.attach-link button, .uploadDesc', context).toggle();
						centerFileUploadActions(context, false);
					}
					return $.fileUploader.createFileWrapper(name, progress);
				},
				setDragOverValues: function (wrapper) {
					// Changing the text breaks the drop event chain in Firefox. setTimeout lets the chain complete like it should.
					setTimeout(function () {
						wrapper
							.addClass('dragOver')
							.find('.upload-msg')
							.text($.__('Upload Files'));
					});
				},
				setDragOffValues: function () {
					$('.dragOver')
						.find('.upload-msg')
						.text($.__('Drop Files Here to Upload'));
					$('.dragOver').removeClass('dragOver');
				},
				uploaderId: 'multiUpload',
			};
			$(function () {
				$('.attach-wrapper', context)
					.closest(fabricModalSelector)
					.fileUploader(options);
			});
			$(fabricModalSelector + ' input[name^=attachId]').each(function () {
				$(this).remove();
			});
			updateUploadInputs('disable');
			setupShareInputs(fabricModalFooterSelector);
			$('#allEmployees').on('change', function () {
				if ($(this).is(':checked')) {
					$(
						'.shareListInputLabel:not(.folderLabel), #shareInput input, #shareInput',
					)
						.addClass('disabled')
						.attr('disabled', 'disabled');
				} else {
					$(
						'.shareListInputLabel:not(.folderLabel), #shareInput input, #shareInput',
					)
						.removeClass('disabled')
						.attr('disabled', null);
				}
			});
		},
		primaryAction: null,
		primaryActionText: $.__('Upload'),
		isOpen: true,
		title: $.__('Upload Files'),
	});
}
