import ReactDOM from 'react-dom';
import { render } from 'base/wrapped-render';
import { PoMicroFrontend } from 'micro-frontend.react';
import { emailFile, renameFile, reloadFileList } from './util/file';
import { deleteCompanyFile } from './delete';
import { shareFile } from './share';
import { FolderSelector } from 'base/employees/documents/components/folder-selector';

// Function to render a new file previewer from Po for employee files
export const openEmployeeFilePreviewer = (fileId) => {
	const root = document.getElementById(`previewer-root`);

	if (root) {
		render(
			<PoMicroFrontend
				fileId={fileId}
				onClose={() => {
					const rootElement = document.getElementById(`previewer-root`);
					if (rootElement) {
						ReactDOM.unmountComponentAtNode(rootElement);
					}
				}}
				onFileInfoChange={() => {
					const rootElement = document.getElementById(`previewer-root`);
					if (rootElement) {
						ReactDOM.unmountComponentAtNode(rootElement);
					}
				}}
				open={true}
				previewerMode="EMPLOYEE"
				route="/previewer"
			/>,
			root,
		);
	}
};

export const openESignatureTemplatingPreviewer = ({
	fileId,
	esignatureTemplateId,
	isTemplating = undefined,
	isDuplicate = undefined,
	pageNumber = undefined,
}: {
	fileId: number;
	esignatureTemplateId?: number;
	isTemplating?: boolean;
	isDuplicate?: boolean;
	pageNumber?: number;
}) => {
	let root = document.getElementById(`previewer-root-${fileId}`);
	if (!root && isDuplicate) {
		root = document.getElementById(`previewer-root-duplicate`);
	}
	// For the companies with many files, some files get pre-loaded on scroll, so when navigating
	// from the quick search bar, there's no corresponding `previewer-root-${fileId}` for those.
	// We need to inject a container for such files to display the previewer.
	if (!root) {
		const filesContentContainer = document.getElementById(`files-content`);
		const newPreviewerRoot = document.createElement('div');
		newPreviewerRoot.id = `previewer-root-hidden-file`;
		filesContentContainer?.appendChild(newPreviewerRoot);
		root = newPreviewerRoot;
	}
	if (root) {
		render(
			<PoMicroFrontend
				deleteCompanyFile={(deletingFileId, onSuccessCallBack) =>
					deleteCompanyFile(deletingFileId, false, false, onSuccessCallBack)
				}
				emailFile={(emailingFileId) => emailFile(emailingFileId, false, false)}
				esignatureTemplateId={esignatureTemplateId}
				fileId={fileId}
				FolderSelector={FolderSelector}
				isDuplicate={!!isDuplicate}
				isTemplating={isTemplating}
				pageNumber={pageNumber}
				onClose={() => {
					const rootElement = document.getElementById(
						`previewer-root-${fileId}`,
					);
					if (rootElement) {
						ReactDOM.unmountComponentAtNode(rootElement);
					}
				}}
				onFileInfoChange={() => {
					window.location.href = '/files';
				}}
				reloadFileList={() => reloadFileList()}
				renameFile={(renamingFileId, currentFileName) =>
					renameFile(renamingFileId, false, false, currentFileName)
				}
				route="/e-signature-templating-previewer"
				requestSignatures={(_templateWorkflowId, _templateName, templateId) => {
					window.Files.SendSignatureRequest.openEsignatureModal(
						templateId,
						$.__('Send Signature Request'),
						true,
					);
				}}
				shareFile={(sharingFileId, sharingFileName) =>
					shareFile(sharingFileId, true, sharingFileName)
				}
				templatingExperienceUtilities={{
					modalIsOpen: window.BambooHR.Modal.isOpen,
					handleCloseTemplatingExperience: () =>
						window.BambooHR.Modal.setState({}),
				}}
			/>,
			root,
		);
	}
};
