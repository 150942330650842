import { openESignatureTemplatingPreviewer } from '../spa';
import { getErrorState, handleStackableModalState } from './helper';
import { decodeEscapeSequence } from 'String.util';
import { getIconNameFromFileName, getFileTypeFromExt } from 'BambooHR.util';
import { ajax } from '@utils/ajax';
import loadPreview from 'pdfjs.lib';
import { Loader } from '@bamboohr/fabric';
import { render } from 'base/wrapped-render';

export function previewFileWithEsignatureTemplating(
	fileId,
	esignatureTemplateId,
) {
	if (empty(fileId)) {
		setMessage($.__(`We don't know how to open that file`), 'error');
		return;
	}
	openESignatureTemplatingPreviewer({ fileId, esignatureTemplateId });
}

export function editSignatureTemplate(
	fileId,
	fileDataId,
	fileName,
	esignatureTemplateId,
) {
	if (window.ASSUMED_USER) {
		window.disabledForPermsTest();
		return;
	}

	openESignatureTemplatingPreviewer({
		fileId,
		esignatureTemplateId,
		isTemplating: true,
	});
}

export function emailFile(fileId, employeeFile, stacked) {
	let emailUrl = employeeFile
		? '/ajax/' + appPrefix + 'employees/files/email_file.php'
		: '/ajax/' + appPrefix + 'files/email_file.php';
	emailUrl += '?id=' + fileId;
	const isDisabled = !BambooHR.Utils.canEmailFilesAndReports();

	handleStackableModalState(stacked, true, { isLoading: true });
	ajax.get(emailUrl).then((response) => {
		const onOpen = () => {
			/**
			 * Choose recipients for email of existing file
			 */
			$('#emailFileForm .input-list').autoInputList({
				autocompleteUrl: '/ajax/' + appPrefix + 'files/quicksearch-email.php',
				description: $.__('Enter email addresses...'),
				formatAutoItem: activeIndicator,
				formatTagItem: function (event, data, formatted) {
					if (data) {
						$(
							`<span class="item">` +
								xmlEntities(data[0]) +
								`
									<input type="hidden" name="ids[]" value='` +
								data[1] +
								`' />
									<button class="remove fab-FloatingIconButton fab-FloatingIconButton--teenie fab-FloatingIconButton--secondary" type="button">
										<span class="fab-FloatingIconButton__icon"><ba-icon name="fab-x-10x10" encore-class="removeIcon" encore-name="circle-x-solid" encore-size="16"></ba-icon></span>
									</button>
								</span>`,
						).insertBefore('.input-list span.input');
						$(this).val('');
						$(document).on('click', '.item .remove', function () {
							$(this).closest('span.item').remove();
							return false;
						});
					}
				},
			});

			$('#emailFileForm .input-list .free').addClass(
				'fab-TextInput fab-TextInput--width100',
			);

			//Focus on "Enter Email Addresses..." field
			const emailPlaceholderVal = $.__('Enter email addresses...');
			$('.free').click().focus().attr('placeholder', emailPlaceholderVal);

			$('#emailFileForm')
				.on('focus', '.input-list .free', function () {
					$('#emailFileForm .input-list').addClass('focused');
					$('#emailTo').removeClass('error');
				})
				.on('blur', '.input-list .free', function () {
					$('#emailFileForm .input-list').removeClass('focused');
				});

			$('#emailFileForm .input-list .description').bind(
				'click.autoInit.bhr',
				function () {
					$('.description')
						.hide()
						.unbind('click.autoInit.bhr')
						.next('.inputs')
						.show();
					$('#emailFileForm .free').focus();
				},
			);
		};

		const modalOptions = {
			alternativeAction: () => {
				handleStackableModalState(stacked, false);
			},
			contentHasPadding: true,
			dangerousContent: response.data,
			footer: isDisabled ? (
				<span>{$.__('Note: Emailing is disabled while in trial.')}</span>
			) : (
				''
			),
			headline: null,
			icon: null,
			isLoading: false,
			onClose: () => {
				handleStackableModalState(stacked, false);
			},
			primaryAction: isDisabled
				? null
				: () => {
						// check for an email address before submitting
						if ($('form#emailFileForm input[name="ids[]"]').length === 0) {
							$('#emailFileForm .js-emailFileFormRecipients').addClass(
								'fab-Label--error',
							);
							return;
						}
						handleStackableModalState(stacked, true, { isProcessing: true });
						ajax
							.post(emailUrl, $('form#emailFileForm').serialize())
							.then(({ data }) => {
								if (data.success) {
									setMessage(data.success_message, 'success');
									handleStackableModalState(stacked, false);
								} else {
									handleStackableModalState(stacked, true, {
										isProcessing: false,
									});
								}
							})
							.catch(() => {
								window.setMessage(
									'This exceeds the limit of external emails that a file can be shared with',
									'error',
								);
								handleStackableModalState(stacked, true, {
									isProcessing: false,
								});
							});
				  },
			primaryActionText: $.__('Send'),
			secondaryAction: null,
			title: $.__('Email This File'),
			type: stacked || !employeeFile ? 'smallSheet' : 'small',
		};

		handleStackableModalState(stacked, true, modalOptions);
		onOpen();
	});
}

/**
 * Preview an employee file in a modal
 *
 * @param {int}     fdId         The file's file_data ID
 * @param {int}     fileId       The file's ID
 * @param {String}  fileName     The name of the file
 * @param {String}  rowSelector  If the data-* info is on an element other than a <tr>, pass in the element name here (Example: "li")

 * @returns {undefined}
 */
export function previewEmployeeFile(fdId, fileId, fileName, rowSelector) {
	previewFile(fdId, fileId, fileName, true, rowSelector);
}

/**
 * Preview a file in a modal
 *
 * @param {int}     fdId                   The file's file_data ID
 * @param {int}     fileId                 The file's ID
 * @param {String}  fileName               The name of the file
 * @param {boolean} employeeFile           Whether the file is an employee file or not
 * @param {String}  rowSelector            If the data-* info is on an element other than a <tr>, pass in the element name here (Example: "li")
 * @param {boolean} hideSidebar            Whether to hide the sidebar or not (default is to show)
 * @param {String}  fileMode               The preview mode of the file (e.g. img or previewer).
 * @param {boolean} isGH	               The preview mode of the file (e.g. img or previewer).
 * @param {int} 	esignatureTemplateId   The template id if the doc has been setup as an esignature
 * @param {boolean} shouldOpenNewPreviewer A flag to see if we should open the new file previewer
 *
 * @returns {void}
 */
export function previewFile(
	fdId,
	fileId,
	fileName,
	employeeFile,
	rowSelector,
	hideSidebar,
	fileMode,
	isGH = false,
	needsReRender = false,
	esignatureTemplateId = null,
	shouldOpenNewPreviewer = false,
	pageNumber = null,
) {
	if (empty(fileId)) {
		setMessage($.__(`We don't know how to open that file`), 'error');
		return;
	}

	if (!employeeFile && shouldOpenNewPreviewer) {
		openESignatureTemplatingPreviewer({
			fileId,
			esignatureTemplateId,
			pageNumber,
		});
		return;
	}

	const html = window.ASSUMED_USER
		? `
	<div id="previewDialog" class="pdfjsViewer isPreviewUser" dir="ltr">
		<div id="documentContainer"></div>
		<div id="documentOptions"></div>
	</div>`
		: `
		<div id="previewDialog" class="pdfjsViewer" dir="ltr">
			<div id="documentContainer"></div>
			<div id="documentOptions" class="fab-Card"></div>
		</div>`;

	employeeFile = employeeFile || false;
	hideSidebar = hideSidebar || false;
	if (
		typeof rowSelector === 'undefined' ||
		rowSelector === '' ||
		rowSelector === null
	) {
		rowSelector = 'tr';
	}
	const row = $(`${rowSelector}[data-file_id="${fileId}"]`);
	const name = fileName || row.data('filename');
	const mode = fileMode || row.data('filemode');
	const title = decodeEscapeSequence(name);
	const isEsig = row.data('is-esig');
	let retriesHandle;

	function clearPrintData() {
		if (window.printPDFInfo && window.clearPrintFile) {
			window.clearPrintFile(window.printPDFInfo);
			window.printPDFInfo = null;
		}
	}

	function onBeforeClose() {
		$(document).trigger('filepreviewer:close:before');
		if (window.webViewerDestroy) {
			window.webViewerDestroy();
		}
		clearTimeout(retriesHandle);
	}

	function onAfterClose() {
		$(document).trigger('filepreviewer:close:after');
		clearPrintData();
		if (window.BambooHR.Modal.isOpen) {
			window.BambooHR.Modal.setState({
				isOpen: false,
			});
		}
	}

	function onBeforeOpen() {
		$(document).trigger('filepreviewer:open:before');
	}

	function onAfterOpen() {
		const $previewDialog = $('#previewDialog'),
			$documentContainer = $previewDialog.find('#documentContainer'),
			$docOptions = $previewDialog.find('#documentOptions');

		//Remove classes anytime a modal is opened. We will add them back later if necessary.
		$previewDialog.removeClass('noSidebar noPreview');

		if (hideSidebar) {
			$docOptions.hide();
			$previewDialog.find('.branded-icon').hide();
			$previewDialog.addClass('noSidebar');
		}

		if (mode == 'img') {
			window.BambooHR.Modal.setState({ isLoading: false }, true);
			const imgContainer = $('<div class="documentImage"></div>'); // Create container
			$documentContainer.append(
				'<div id="loadingMsg">' +
					$.__('Loading Image...') +
					'<img class="loader" src="/images/setup/ajax-loader.gif"/>',
			);

			render(<Loader small={true} />, document.getElementById('loadingMsg'));

			$documentContainer.find('#noPreviewMsg').remove();
			$documentContainer.find('img.img-preview').remove();
			$documentContainer.append(imgContainer); // Append Container
			imgContainer.html(
				'<img onload="showImage(this)" class="img-preview" src="/ajax/' +
					appPrefix +
					'fetcher.php?doc=' +
					fdId +
					'&format=img' +
					(employeeFile ? '&employee=true' : '') +
					'"/></div>',
			);
		} else {
			$documentContainer.find('#noPreviewMsg').remove();
			$documentContainer.find('#loadingMsg').remove();
			$documentContainer.find('img.img-preview').remove();
			$documentContainer.append(
				'<div id="loadingMsg">' +
					$.__('Loading Preview...') +
					'<img class="loader" src="/images/setup/ajax-loader.gif"/></div>',
			);

			render(<Loader small={true} />, document.getElementById('loadingMsg'));

			let file_download_url =
				'/' + (appPrefix ? appPrefix : '') + 'files/download.php?id=' + fileId;
			let file_preview_status_url =
				'/ajax/' + appPrefix + 'file_preview_status.php?v=' + fdId;
			if (employeeFile == true) {
				file_download_url =
					'/' +
					(appPrefix ? appPrefix : '') +
					'employees/files/download.php?id=' +
					fileId;
				file_preview_status_url =
					'/ajax/' +
					appPrefix +
					'file_preview_status.php?v=' +
					fdId +
					'&employee=true';
			}
			if (isGH) {
				// todo put new endpoints here when BE is finished
				// file_download_url = `/greenhouse/${ file_download_url }`;
				// file_preview_status_url = `/greenhouse/${ file_preview_status_url }`;
			}
			if (appPrefix === 'offer_letter/') {
				file_download_url = `/applicant_tracking/download_attachment?id=${fileId}`;
			}

			window.downloadPdfjsFile = function () {
				window.location.href = file_download_url;
			};

			let tries = 0;
			const getPreview = function () {
				tries++;
				// after the first request, we only want the status
				$.get(
					file_preview_status_url + (tries > 1 ? '&status-only' : ''),
					function (data, status, jqXHR) {
						const errorMessage = jqXHR.getResponseHeader(
							'x-bamboohr-error-message',
						);
						if (errorMessage) {
							window.setMessage(errorMessage, 'error');
							onAfterClose();
							return false;
						}
						window.BambooHR.Modal.setState({ isLoading: false }, true);
						if (data.res === 'yes') {
							showPreview();
						} else if (data.res === 'failed') {
							if (data.s3 === 'yes') {
								$documentContainer.html(
									getErrorState({
										fabIcon: 'fab-file-sad-100x120',
										encoreIcon: 'bs-tumbleweed',
										encoreSize: '126',
										icon: 'file-sad.png',
										title: $.__(
											'Sorry, we can’t provide a preview for this file.',
										),
										subText: $.__(
											'You can always download and view it on your computer.',
										),
										fileUrl: file_download_url,
									}),
								);
								$documentContainer.find('#loadingMsg').remove();
							} else {
								$documentContainer.html(
									getErrorState({
										icon: 'file-hourglass.png',
										title: $.__(
											'We are working on generating a preview for this file. Please check back later.',
										),
										subText: $.__(
											'You can always download and view it on your computer.',
										),
										fileUrl: file_download_url,
									}),
								);
								$documentContainer.find('#loadingMsg').remove();
							}
						} else if (data.error && data.error == 'missing file data') {
							$documentContainer.html(
								getErrorState({
									fabIcon: 'fab-file-sad-100x120',
									encoreIcon: 'bs-tumbleweed',
									encoreSize: '126',
									icon: 'file-sad.png',
									title: $.__('There was a problem uploading this file.'),
									subText: $.__(
										'This file could not be processed, try uploading it again.',
									),
								}),
							);
							$documentContainer.find('#loadingMsg').remove();
						} else if (data.error && data.error === 'archived') {
							$('.js-simplemodal-title').text($.__('This file is gone'));
							$('.js-simplemodal-button-primary').text($.__('Close'));
							$documentContainer.html(
								getErrorState({
									icon: 'file-dinosaur.png',
									title: $.__("Sorry, this doesn't exist anymore."),
									subText: $.__(
										'Talk to your HR Administrator to see if they can dig it up.',
									),
								}),
							);
							$documentContainer.find('#loadingMsg').remove();
						} else if (tries < 3) {
							retriesHandle = setTimeout(getPreview, 2000 * Math.min(tries, 5));
						} else {
							$documentContainer.html(
								getErrorState({
									fabIcon: 'fab-file-sad-100x120',
									icon: 'file-sad.png',
									encoreIcon: 'bs-tumbleweed',
									encoreSize: '126',
									title: $.__(
										'Sorry, we can’t provide a preview for this file.',
									),
									subText: $.__(
										'You can always download and view it on your computer.',
									),
									fileUrl: file_download_url,
								}),
							);
							$documentContainer.find('#loadingMsg').remove();
						}
					},
					'json',
				).fail(function (jqxhr, status, error) {
					console.log('why?');
					console.log(jqxhr);
					console.log(status);
					console.log(error);
				});
			};

			getPreview();
		}
		if (!hideSidebar) {
			// now get the right side options
			let sidebarUri =
				'/ajax/' + appPrefix + 'files/previewer_sidebar.php?id=' + fileId;
			if (employeeFile) {
				sidebarUri += '&employee_file=true';
			}
			if (isGH) {
				// todo put new endpoint here when BE is finished
				// sidebarUri = `/greenhouse/${ sidebarUri }`;
			}
			$.get(
				sidebarUri,
				function (response) {
					if (response.success) {
						$docOptions
							.show()
							.css('opacity', 0)
							.html(response.html)
							.velocity({ opacity: 1 }, { duration: 150 });
					}
				},
				'json',
			);
		}

		function getDocumentUrl(fddid) {
			let url =
				'/ajax/' + appPrefix + 'fetcher.php?doc=' + fddid + '&format=pdf';
			if (employeeFile) {
				url += '&employee=true';
			}
			if (isGH) {
				// todo put new endpoint here when BE is finished
				// url = `/greenhouse/${ url }`;
			}
			return url;
		}

		function showPreview() {
			loadPreview(
				'/ajax/' + appPrefix + 'files/previewer/load_html.php/previewerHtml',
				getDocumentUrl(fdId),
				$documentContainer,
			);
		}
	}

	onBeforeOpen();
	window.BambooHR.Modal.setState({
		alternativeAction: null,
		contentHasPadding: true,
		dangerousContent: html,
		header: (
			<div className="filePreviewModalHeader">
				<h2 className="filePreviewModalHeader__title mobileHide">
					{undefined}
					{title}
				</h2>
				<h3 className="filePreviewModalHeader__title hidden mobileOnly mobileOnly--block">
					{undefined}
					{title}
				</h3>
			</div>
		),
		isHeadless: true,
		isLoading: true,
		isOpen: true,
		onClose: onAfterClose,
		onOpen: onAfterOpen,
		primaryAction: () => {
			onBeforeClose();
			clearPrintData();
			window.BambooHR.Modal.setState({ isOpen: false });
		},
		primaryActionKey: null,
		primaryActionText: $.__('Close'),
		type: 'fullScreen',
	});

	if (needsReRender) {
		onAfterOpen();
	}
}

export function reloadFileList() {
	window.resetFiles();
	window.loadSectionFiles(
		$('.sectionLink.selected .js-FileFolder').data('section_id'),
		$('select[name=sort-files]').val(),
	);
}

export function duplicateFile(
	Esignatures,
	templateId,
	title,
	fileDataId,
	icon,
) {
	if (window.GLOBAL_IS_ASSUMED_USER) {
		return window.disabledForPermsTest();
	}

	if (templateId) {
		ajax
			.post(`/esignature_templates/${templateId}/duplicate`)
			.then((response) => {
				if (
					response.status == 200 &&
					response?.data?.meta?.files &&
					response.data.meta.files.length > 0
				) {
					const newFileId = response.data.meta.files[0].id;
					const newTemplateId = response.data.result?.template?.id;
					openESignatureTemplatingPreviewer({
						fileId: newFileId,
						esignatureTemplateId: newTemplateId,
						isTemplating: true,
						isDuplicate: true,
					});
				} else {
					setMessage(
						response?.error
							? response.error
							: $.__('Uh-oh. Something went wrong, please try again!'),
						'error',
					);
				}
			})
			.catch((error = {}) => {
				const {
					response: {
						data: {
							error: {
								message = $.__(
									'Uh-oh. Something went wrong, please try again!',
								),
							} = {},
						} = {},
					} = {},
				} = error;

				console.error(error);
				setMessage(message, 'error');
			});
	}
}

export function renameFile(fileId, employeeFile, stacked, fileNameNoJquery) {
	const $row = $(`[data-file_id="${fileId}"], [data-file-id="${fileId}"]`);
	const fileName =
		$row.data('filename') || $row.data('fileName') || fileNameNoJquery || '';
	const currentFileName = decodeEscapeSequence(fileName);

	const data = {
		name: currentFileName,
		fclass: `filetype-${getFileTypeFromExt(currentFileName)}`,
	};

	const url = employeeFile
		? `/ajax/${appPrefix}employees/files/rename_file.php`
		: `/${appPrefix}files/rename_file`;

	handleStackableModalState(stacked, true, { isLoading: true });
	ajax.get(`${url}?${$.param(data)}`).then((response) => {
		const modalOptions = {
			alternativeAction: () => {
				handleStackableModalState(stacked, false);
			},
			contentHasPadding: true,
			dangerousContent: response.data,
			footer: null,
			headline: null,
			icon: null,
			isLoading: false,
			onOpen: () => {
				$('#fileName').select().focus();
			},
			onClose: () => {
				handleStackableModalState(stacked, false);
			},
			primaryAction: () => {
				const fileNameValue = $('.js-renameInput').val();

				if (fileNameValue === '') {
					setMessage($.__('Please enter a name for this file.'), 'error');
				} else {
					$.ajax({
						url: url,
						type: 'post',
						data: {
							id: fileId,
							name: fileNameValue,
						},
						success: function (data) {
							if (data.success) {
								// update the ui with the new name
								setMessage(data.success_message, 'success');
								let modalOptions = { isOpen: false };
								if (stacked) {
									modalOptions = {
										title: fileNameValue,
										sheetProps: { isOpen: false },
									};
								}
								handleStackableModalState(stacked, false, modalOptions);
								const renameFileSuccessFn =
									window.renameFileSuccess || renameFileSuccess;
								renameFileSuccessFn(fileId, fileNameValue, fileName);
							} else {
								setMessage(data.error, 'error');
							}
						},
						dataType: 'json',
					});
				}
			},
			primaryActionKey: 'Enter',
			primaryActionText: $.__('Rename File'),
			secondaryAction: false,
			title: $.__('Rename File'),
			type: stacked || !employeeFile ? 'smallSheet' : 'small',
		};

		handleStackableModalState(stacked, true, modalOptions);
	});
}

export function renameFileSuccess(fileId, newName, oldName = '') {
	const escapedFileName = window.js(newName);
	const $row = $(`tr[data-file_id=${fileId}]`);
	const isEsig = $row.data('is-esig');

	$row.find('.js-fileName').text(newName);
	$row.data('filename', newName);

	//TODO: this needs updating with file wrappers
	const $fileWrappers = $(
		'.file-wrapper, .FileWrapper, .FileUploadsEmployee__File, .js-FileCard',
	);
	let $fileWrapper = $fileWrappers.filter(
		`[id="file-${fileId}"], [id="${fileId}"], [data-fileid="${fileId}"], [data-file_id="${fileId}"]`,
	);
	if ($fileWrapper.length === 0) {
		$fileWrapper = $fileWrappers.find(`[data-file-id="${fileId}"]`);
	}
	const $fileLink = $fileWrapper.find(
		'.js-FileWrapper__FileLink, .js-Comment__fileName, .js-Comment__fileName-link',
	);
	const $fileName = $fileLink.find('.FileName__Text, > span');
	const $fileIcon = $fileWrapper.find('ba-icon.FileType__icon');
	$fileName.text(newName);
	$fileLink.attr('data-file-name', escapedFileName);
	$fileLink.data('fileName', newName);
	if ($fileLink.length > 0 && $fileLink[0].hasAttribute('onclick')) {
		$fileLink.attr(
			'onclick',
			$fileLink.attr('onclick').replace(oldName, escapedFileName),
		);
	}
	$fileIcon.attr('name', getIconNameFromFileName(newName, '16x20'));

	window.BambooHR.Modal.setState(
		{
			header: (
				<div className="filePreviewModalHeader">
					<h2 className="filePreviewModalHeader__title">
						<span className="filePreviewModalHeader__icon">
							<ba-file-icon
								esig={isEsig ? 'true' : 'false'}
								name={newName}
								size="medium"
							></ba-file-icon>
						</span>
						{newName}
					</h2>
				</div>
			),
		},
		true,
	);
}

//call the ajax to scan the files
export function runScan(fileIds) {
	if (!fileIds) {
		return;
	}
	$.ajax({
		url: '/ajax/' + appPrefix + 'file_status.php',
		data: {
			ids: fileIds,
		},
		dataType: 'json',
		async: false,
		success: function (data) {
			for (let fileId in data) {
				const ele = $('[data-file_id=' + fileId + ']');
				if (data[fileId] == 'uploaded') {
					ele.attr('data-scanstatus', 'complete');
				} else if (data[fileId] == 'virus') {
					ele
						.attr('data-scanstatus', 'virus-detected')
						.attr('data-fileimage', 'virus.png');
					ele
						.find('td.file-icon img')
						.attr('src', '/images/files/types/virus.png')
						.removeAttr('width')
						.removeAttr('height');
					const numFilesWithViruses = $(
						'[data-scanstatus="virus-detected"]',
					).length;
					setMessage(
						$.__n(
							'Warning: One of these files has a virus.',
							'Warning: Some of these files have a virus.',
							numFilesWithViruses,
						),
						'info',
					);
				}
			}
			if (fileScanTimeout < 120000) {
				fileScanTimeout *= 1.5;
				window.setTimeout(function () {
					scanFiles(fileScanTimeout);
				}, fileScanTimeout);
			}
		},
	});
}

// gets file ids that need to be scanned and runs the scan
export function scanFiles() {
	let ids = [];
	$('[data-scanstatus=scanning]').each(function (idx) {
		ids.push($(this).data('file_id'));
	});
	if (ids.length) {
		runScan(ids.join(','));
	}
}

export function showEmployeeFile(fdId, fileId, fileName) {
	showFile(fdId, fileId, fileName, true);
}
export function showFile(fdId, fileId, fileName, employeeFile, rowSelector) {
	if (typeof rowSelector === 'undefined' || rowSelector === '') {
		rowSelector = 'tr';
	}

	let file_download_url = `/${appPrefix || ''}files/download.php?id=${fileId}`;
	if (employeeFile == true) {
		file_download_url = `/${
			appPrefix || ''
		}employees/files/download.php?id=${fileId}`;
	}
	if (appPrefix === 'offer_letter/') {
		file_download_url = `/applicant_tracking/download_attachment?id=${fileId}`;
	}

	const html =
		'<div id="previewDialog">' +
		'<div id="documentContainer">' +
		//'<div id="noPreviewMsg">' + $.__('A preview is not available for this type of file.') + '</div>' +
		getErrorState({
			encoreIcon: 'bs-tumbleweed',
			encoreSize: '126',
			fabIcon: 'fab-file-sad-100x120',
			icon: 'file-sad.png',
			title: $.__('Sorry, we can’t provide a preview for this file.'),
			subText: $.__('You can always download and view it on your computer.'),
			fileUrl: file_download_url,
		}) +
		'</div>' +
		'<div class="branded-icon"></div>' +
		'<div id="documentOptions"></div>' +
		'</div>';

	const row = $(rowSelector + '[data-file_id=' + fileId + ']');
	const $previewDialog = $('#previewDialog');
	const $documentContainer = $previewDialog.find('#documentContainer');
	const name = fileName || row.data('filename');
	$documentContainer.find('#noPreviewMsg').remove();
	$documentContainer.find('#loadingMsg').remove();
	$previewDialog.addClass('noPreview');

	function onAfterOpen() {
		let sidebarUri =
			'/ajax/' + appPrefix + 'files/previewer_sidebar.php?id=' + fileId;
		if (employeeFile) {
			sidebarUri += '&employee_file=true';
		}
		$.get(
			sidebarUri,
			function (response) {
				if (response.success) {
					$('#documentOptions')
						.css('opacity', 0)
						.html(response.html)
						.velocity({ opacity: 1 }, { duration: 150 });
				}
			},
			'json',
		);
	}

	window.BambooHR.Modal.setState({
		dangerousContent: html,
		header: (
			<div className="filePreviewModalHeader">
				<h2 className="filePreviewModalHeader__title">
					<span className="filePreviewModalHeader__icon">
						<ba-file-icon name={name} size={'medium'}></ba-file-icon>
					</span>
					{name}
				</h2>
			</div>
		),
		isOpen: true,
		onOpen: onAfterOpen,
		primaryAction: () => {
			window.BambooHR.Modal.setState({ isOpen: false });
		},
		primaryActionText: $.__('Done'),
		type: 'fullScreen',
	});
}

export function showFileNoAccess(filename) {
	$.get(
		'/ajax/' + appPrefix + 'files/no_access.php',
		{
			filename: filename,
		},
		function (html) {
			window.BambooHR.Modal.setState(
				{
					dangerousContent: html,
					title: $.__("There's nothing to see here..."),
					isOpen: true,
					isLoading: false,
					primaryAction: () => {
						window.BambooHR.Modal.setState({ isOpen: false });
					},
					primaryActionText: $.__('Move Along'),
					alternativeActionText: '',
				},
				true,
			);
		},
		'html',
	).fail(errorFallBack);
}
