import { setupDroppable } from './helper';
import { upload, uploadEmployeeFile } from '../upload';

export function addFolderSuccess(sectionId, newName, canEdit, employee) {
	let $newLi = $('#file-sections > li:last-child')
		.clone()
		.attr('data-section_id', sectionId)
		.attr('data-foldername', newName)
		.attr('title', newName);
	// handle first folder

	let data = {
		sectionId: sectionId,
		sectionName: newName,
		sectionNameJSEncoded: newName.replaceAll("'", "\\'"),
		sectionDropdownButtonFloating: true,
		sectionLinkClasses: 'fab-SideNavigation__link',
		sectionNameClasses: '',
	};

	if (!$newLi.hasClass('all') || !$newLi.length) {
		const tmplId = employee
			? '#firstEmployeeFolderMenuTemplate'
			: '#firstFolderMenuTemplate';
		$newLi = $(tmplId)
			.tmpl(data)
			.attr('data-section_id', sectionId)
			.attr('data-foldername', newName);
	}
	if (employee) {
		$('.blankState').hide();
		$newLi
			.find('.files-section-wrapper')
			.attr('id', 'files-table-wrapper_' + sectionId)
			.empty();
	}

	$newLi.appendTo('#file-sections');
	$newLi.removeClass('shared');
	$newLi.removeClass('selected');

	if (canEdit) {
		$newLi.addClass('droppable js-canUpload');
		setupDroppable($newLi, employee);
	} else {
		$newLi.removeClass('droppable');
	}
	const sortByName = function (a, b) {
		const sb = $(a).find('span.sectionName').text().toLowerCase();
		const sa = $(b).find('span.sectionName').text().toLowerCase();
		let ret = 0;
		if (sb < sa) {
			ret = -1;
		} else if (sb > sa) {
			ret = 1;
		}
		return ret;
	};

	let list = $('#file-sections > li:not(.all)');
	list.sort(sortByName);
	for (let i = 0; i < list.length; i++) {
		list[i].parentNode.appendChild(list[i]);
	}
}

export function editFolder(
	sectionId,
	action,
	employee = false,
	employeeId = null,
) {
	const data = {
		name: $('[data-section_id=' + sectionId + ']').data('foldername'),
	};
	let templateId;
	let modalTitle = '';
	switch (action) {
		case 'add':
			templateId = '#addFolderTemplate';
			modalTitle = $.__('Add Folder');
			break;
		case 'first':
			templateId = '#firstFolderTemplate';
			modalTitle = $.__('Create a Folder First');
			if (employee) {
				action = 'add';
			}
			break;
		case 'rename':
			templateId = '#renameFolderTemplate';
			modalTitle = $.__('Rename Folder');
			break;
	}
	const html = $(templateId).tmpl(data);
	window.BambooHR.Modal.setState({
		dangerousContent: html.html(),
		isOpen: true,
		onClose: () => {
			window.BambooHR.Modal.setState({
				isOpen: false,
			});
		},
		primaryAction: function () {
			const folderNameValue = $('#fabricModalContent #folderName').val().trim();
			if (folderNameValue === '') {
				setMessage($.__('Please enter a name for this folder.'), 'error');
			} else {
				let url = '/ajax/' + appPrefix + 'files/edit_folder.php';
				let data = {
					id: sectionId,
					name: folderNameValue,
					action: action,
					employeeId: employeeId,
				};

				if (employee) {
					url =
						'/employees/' +
						appPrefix +
						'ajax/folder/' +
						action +
						'/' +
						employeeId;
					data = {
						id: sectionId,
						name: folderNameValue,
						employeeId: employeeId,
					};
				}

				$.ajax({
					type: 'post',
					url: url,
					data: data,
					success: function (data) {
						if (data.success) {
							// update the ui with the new name
							setMessage(data.success_message, 'success');
							window.BambooHR.Modal.setState({ isOpen: false });
							switch (action) {
								case 'add':
									addFolderSuccess(
										data.section_id,
										folderNameValue,
										data.can_edit,
										employee,
									);
									break;
								case 'first':
									firstFolderSuccess(
										data.section_id,
										folderNameValue,
										data.can_edit,
										employee,
									);
									break;
								case 'rename':
									renameFolderSuccess(sectionId, folderNameValue);
									break;
							}
						} else {
							setMessage(data.error, 'error');
						}
					},
					dataType: 'json',
				});
			}
		},
		primaryActionKey: 'Enter',
		primaryActionText: $.__('Save'),
		title: modalTitle,
	});
	$('#fabricModalContent #folderName').select().focus();
}

export function EmployeeFolderClicked(ele) {
	const item = $(ele).parent('.sectionLink');
	const secId = $(item).data('section_id');
	if ($(item).hasClass('selected')) {
		// close it up
		$(item).removeClass('selected');
		$(item).find('.files-section-wrapper').hide();
		return;
	}
	$(item).addClass('selected');
	loadSectionFiles(secId, $('select[name=sort-files]').val());
}

export function firstFolderSuccess(sectionId, newName, canEdit, employee) {
	addFolderSuccess(sectionId, newName, canEdit, employee);
	// since it's the only folder - set it as selected
	$('li.sectionLink.selected').removeClass('selected');
	$('li.sectionLink[data-section_id=' + sectionId + ']').addClass('selected');

	if (employee) {
		$('.blankState').hide();
		return uploadEmployeeFile(employee);
	}
	upload();
}

export function renameFolderSuccess(sectionId, newName) {
	let $li = $('li[data-section_id=' + sectionId + ']');

	$li = $('.js-FileFolder[data-section_id=' + sectionId + ']');
	$li.find('.sectionName').text(newName);

	$li.data('foldername', newName);
	$li.attr('title', newName);

	if ($li.hasClass('selected')) {
		$('#files-content #section-name .name').text(newName);
	}
}
