import {
	Button,
	IconV2,
	StandardModal,
	TextButton,
	createStyles,
	makeStyles,
} from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';
import { ColoredIcon } from 'colored-icon';
import { ReactElement, useState } from 'react';
import { closeModal } from 'show-modal.mod';
// TODO: Attach to files/folders 2.0 toggles - hard coded for now
const isEmployeeDeleteListEnabled = true;

interface Employee {
	count: number;
	employee_id: string;
	employee_name: string;
	id: string;
}

interface Props {
	fileCount: number;
	employeeCount: number;
	hasTemplates: boolean;
	employeeId: number;
	sectionId: number;
	affectedEmployees: Array<Employee>;
	deleteAction: (
		employeeId: number,
		sectionId: number,
		fileCount: number,
	) => Promise<void>;
}

const styles = makeStyles(({ palette, typography }) => {
	return createStyles({
		subheader: {
			marginTop: '5px',
			color: palette.grey[900],
			textAlign: 'center',
		},
		disclaimer: {
			display: 'flex',
			margin: '0 auto',
		},
		disclaimerIcon: {
			marginRight: '10px',
			paddingLeft: '10px',
			fontSize: typography.fabricFontSize('small'),
			height: '50px',
		},
		disclaimerText: {
			flex: '1',
			color: palette.grey[900],
			textAlign: 'left',
			fontSize: typography.fabricFontSize('small'),
		},
		confirmationContentOverride: {
			'& input.fab-TextInput': {
				width: '64px',
			},
			'& input.fab-TextInput:focus': {
				borderColor: 'rgb(194, 11, 11) !important',
				boxShadow: '0 1px 0 0 rgba(194, 11, 11, .33) !important',
			},
		},
		employeeList: {
			marginTop: '5px',
			marginBottom: '10px',
		},
		employeeName: {
			color: palette.grey[900],
		},
		fileCount: {
			color: palette.error.main,
		},
	});
});

export function DeleteEmployeeFolderModal({
	fileCount,
	employeeCount,
	hasTemplates,
	employeeId,
	sectionId,
	deleteAction,
	affectedEmployees,
}: Props): ReactElement {
	const classes = styles();
	const [isValidConfirmation, setIsValidConfirmation] = useState(false);
	const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(true);
	const primaryButtonText = $.__n(
		'Delete from %1 Employee',
		'Delete from %1 Employees',
		employeeCount,
	);
	const subHeaderText = (
		<Message
			text={$._(
				"That's right. This folder and all files in it will be deleted from all employees.",
			)}
		/>
	);
	const confirmationText = (
		<Message
			params={[employeeCount]}
			text={$._n(
				'Type "**{1}**" to show you realize that {1} employee will lose files.',
				'Type "**{1}**" to show you realize that {1} employees will lose files.',
				employeeCount,
			)}
		/>
	);
	const employeeListWarning = (
		<Message
			params={[affectedEmployees.length]}
			text={$._n(
				'{1} employee will lose files:',
				'{1} employees will lose files:',
				affectedEmployees.length,
			)}
		/>
	);
	const disclaimer = hasTemplates ? (
		<div className={classes.disclaimer}>
			<div className={classes.disclaimerIcon}>
				<IconV2 color="neutral-extra-strong" name="folder-solid" size={12} />
			</div>
			<div className={classes.disclaimerText}>
				<Message
					text={$._(
						'Also, 1 or more E-signature templates are set to save to this folder. They will now be saved in the **Signed Documents** folder.',
					)}
				/>
			</div>
		</div>
	) : null;

	const employeeList = [];

	affectedEmployees.forEach((employee) =>
		employeeList.push(
			<p className={classes.employeeName}>
				{employee.employee_name}{' '}
				<span className={classes.fileCount}>
					({$.__n('%1 file', '%1 files', employee.count)})
				</span>
			</p>,
		),
	);
	const employeeDisplay = isEmployeeDeleteListEnabled ? (
		<div>
			<b>{employeeListWarning}</b>
			<div className={classes.employeeList}>{employeeList}</div>
		</div>
	) : null;
	let headerText;

	if (fileCount === 1) {
		headerText = $.__n(
			"You're about to delete %1 file from %2 employee",
			"You're about to delete %1 file from %2 employees",
			fileCount,
			employeeCount,
		);
	} else {
		headerText = $.__n(
			"You're about to delete %1 files from %2 employee",
			"You're about to delete %1 files from %2 employees",
			fileCount,
			employeeCount,
		);
	}

	function onRequestClose() {
		setDeleteModalIsOpen(false);
	}

	function onChange(e) {
		if (Number(e.target.value) === Number(employeeCount)) {
			setIsValidConfirmation(true);
		} else {
			setIsValidConfirmation(false);
		}
	}

	function teardown() {
		closeModal();
	}

	function confirmDeletion() {
		deleteAction(employeeId, sectionId, fileCount).then(function () {
			onRequestClose();
		});
	}

	return (
		<StandardModal
			isOpen={deleteModalIsOpen}
			onCloseComplete={teardown}
			onRequestClose={onRequestClose}
		>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<TextButton
								key="secondary"
								onClick={confirmDeletion}
								disabled={!isValidConfirmation}
								type="button"
							>
								{primaryButtonText}
							</TextButton>,
							<Button key="primary" onClick={onRequestClose} type="button">
								{$._("Don't Delete")}
							</Button>,
						]}
					/>
				}
				renderHeader={
					<StandardModal.Header
						hasCloseButton={true}
						title={$.__('Just Checking...')}
					/>
				}
			>
				<StandardModal.Constraint>
					<StandardModal.HeroHeadline
						icon="trash-can-regular"
						iconColor="error-strong"
						text={headerText}
					/>
					<div className={classes.subheader}>{subHeaderText}</div>

					<div className={classes.confirmationContentOverride}>
						<StandardModal.Constraint>
							<StandardModal.ConfirmationContent
								confirmationText={
									<div>
										{employeeDisplay}
										<p>
											{isEmployeeDeleteListEnabled
												? ''
												: $.__('We really want to make sure this is clear.')}
										</p>
										<p>{confirmationText}</p>
									</div>
								}
								onConfirmationChange={onChange}
							></StandardModal.ConfirmationContent>
						</StandardModal.Constraint>
					</div>
					<div>{disclaimer}</div>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
}
